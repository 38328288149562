import { ExportTimesheetTypes } from "../../enums/export-timesheet-types.enum";

export class UpdateFiltersToExport {
	public static readonly type = "[Export Timesheet] update filters";
	constructor(
		public payrollPeriod: number,
		public year: number,
		public isReExport: boolean,
		public submit: boolean
	) {}
}

export class SetExportType {
	public static readonly type = "[Export Timesheet] set export type";
	constructor(
		public exportType: ExportTimesheetTypes
	) {}
}

export class LoadSummary {
	public static readonly type = "[Export Timesheet] load summary"
}

export class ResetExportTimesheetState {
	public static readonly type = "[Export Timesheet] reset export timesheet state";
}